<script>
import appConfig from "../../../app.config";
import { reset } from "@/helpers/auth";
import validate from "@/helpers/validate";

export default {
  page: {
    title: "Востановление пароля",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      password: '',
      loading: false,
      confirm: '',
      errors: {},
    };
  },
  methods: {
    async tryToReset() {
      this.errors = {};
      this.errors.password = validate.password(this.password);
      this.errors.confirm = validate.confirm(this.confirm, this.password);

      if (this.errors.password || this.errors.confirm) {
        return;
      }

      this.loading = true;
      await reset({
        login: this.$route.params.login,
        password: this.password,
        token: this.$route.params.token
      });
      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
        >
          <path
              d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img
                      src="@/assets/images/logo-light.png"
                      alt=""
                      height="40"
                  />
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-medium">
                Добро пожаловать домой!
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4" data-aos="fade-up">
              <div v-if="loading" class="card-preloader d-flex flex-column align-items-center justify-content-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
                <p class="mt-2">Отправка сообщения...</p>
              </div>

              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Хотите сбросить пароль?</h5>
                  <p class="text-muted">Укажите новые данные!</p>
                </div>

                <div class="p-2">
                  <form @submit.prevent="tryToReset">
                    <div class="mb-4">
                      <label class="form-label" for="password">Новый пароль</label>
                      <input
                          type="password"
                          v-model="password"
                          class="form-control"
                          id="password"
                          :class="{ 'is-invalid': errors.password }"
                          placeholder="Укажите новый пароль"
                      />
                      <div
                          v-for="(item, index) in errors.password"
                          :key="index"
                          class="invalid-feedback"
                      >
                        <span v-if="item">{{ item }}</span>
                      </div>
                    </div>

                    <div class="mb-4">
                      <label class="form-label" for="confirm">Повторите пароль</label>
                      <input
                          type="password"
                          v-model="confirm"
                          id="confirm"
                          class="form-control"
                          :class="{ 'is-invalid': errors.confirm }"
                          placeholder="Укажите новый пароль ещё раз"
                      />
                      <div
                          v-for="(item, index) in errors.confirm"
                          :key="index"
                          class="invalid-feedback"
                      >
                        <span v-if="item">{{ item }}</span>
                      </div>
                    </div>

                    <div class="text-center mt-4">
                      <button class="btn btn-success w-100" type="submit">
                        Сбросить пароль
                      </button>
                    </div>
                  </form>
                  <!-- end form -->
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div class="mt-4 text-center" data-aos="fade-in">
              <p class="mb-0">
                Подождите, Я вспомнил свой паро...
                <router-link
                    to="/login"
                    class="fw-semibold text-primary text-decoration-underline"
                >
                  Кликни меня
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <div class="text-center">
                <p class="mb-0 text-muted">
                  &copy; {{new Date().getFullYear()}} FinalDream. Сделано с любовью
                  <i class="mdi mdi-heart text-danger"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
